// extracted by mini-css-extract-plugin
export var adsList = "_2022-jano-module--adsList--16748";
export var columnInner = "_2022-jano-module--columnInner--28e91";
export var contentPadding = "_2022-jano-module--contentPadding--010c5";
export var grid = "_2022-jano-module--grid--633ee";
export var hoverable = "_2022-jano-module--hoverable--0bceb";
export var logo = "_2022-jano-module--logo--f237e";
export var root2022 = "_2022-jano-module--root2022--50c3c";
export var synopsisStyle2022 = "_2022-jano-module--synopsisStyle2022--642fd";
export var titleImg = "_2022-jano-module--titleImg--71e9e";
export var toggleBox = "_2022-jano-module--toggleBox--b2fd1";
export var toggleBoxGrid = "_2022-jano-module--toggleBoxGrid--a8660";
export var toggleTitle = "_2022-jano-module--toggleTitle--2ce8e";