import React from 'react';
import { Helmet } from 'react-helmet';

import DefaultLayout from '../layouts';
import ToggleBox from '../components/toggle-box';
import Metadata from '../components/metadata';

import * as styles from './2022-jano.module.css';

import titleImg from '../assets/2022/jano.svg';

import etasku from '../assets/common/ads/etasku.png';
import riemurinne from '../assets/common/ads/riemurinne_netti.png';
import poas from '../assets/common/ads/poas.svg';
import tek from '../assets/common/ads/tek.svg';

// --- all required data definitions below  ---

const sponsors = [
  { href: 'https://www.etasku.fi/', src: etasku, title: 'eTasku' },
  { href: 'https://www.riemurinne.fi/', src: riemurinne, title: 'Riemurinne' },
  { href: 'https://www.poas.fi/', src: poas, title: 'POAS' },
  { href: 'https://www.tek.fi/fi', src: tek, title: 'TEK' },
];

const crew = [
  {
    title: 'Tuottajat',
    members: [
      'Antti Kemppi',
      'Katja Kujala',
      'Santtu Laine',
    ],
  },
  {
    title: 'Ohjaaja',
    members: ['Harri Kailanto'],
  },
  {
    title: 'Ohjaajan assistentti',
    members: ['Leena Jarkko'],
  },
  {
    title: 'Laulunopettaja',
    members: [
      'Jenni Arponen',
    ],
  },
  {
    title: 'Äänenkäytön opettaja',
    members: [
      'Meeri Härmä',
    ],
  },
  {
    title: 'Näyttelijät',
    members: [
      'Emma Brown - Mutsi',
      'Emma Taalikka - Pösö',
      'Fanni Vesa - Kira',
      'Jari Leppänen - Renu',
      'Jenni Arponen - Muukalainen',
      'Miikka Saraniemi - Niila, Subarukokelas',
      'Peitsa Leinonen - Mosse',
      'Pyry Qvick - Marshall',
      'Riikka Koivisto - Glamrokkari',
      'Roope Salonen - Glamrokkari',
      'Suvi Huotari - Glamrokkari',
    ],
  },
  {
    title: 'Bändi',
    members: [
      <strong>Enni Sipilä - laulu</strong>,
      <strong>Niko Gullsten - kitara</strong>,
      'Atte Pietarinen - basso',
      'Heidi Hansen-Haug - laulu',
      'Johannes Heikkilä - koskettimet',
      'Juho Viitala - rummut',
      'Milla Tyyskä - saksofoni',
      'Riku Breilin - koskettimet',
      'Sini Manninen - viulu',
    ],
  },
  {
    title: 'Sovitus',
    members: [
      'Bändi',
      'Petteri Niemi',
    ],
  },
  {
    title: 'Hyvinvointi',
    members: [
      <strong>Emma Brown</strong>,
      'Alfred Altsten',
      'Essi Kyyrönen',
      'Helena Ukkola',
      'Katariina Lilja',
      'Krista Lappalainen',
      'Mia Lahtinen',
      'Mikko Kohonen',
      'Mikko Tiitola',
      'Minnow Lappi',
      'Saana Haapala',
      'Sanna Kallio',
      'Sara Nortunen',
      'Sonja Haapala',
      'Sonja Mikkola',
      'Tiitus Lehtonen',
      'Verna Lukkari',
    ],
  },
  {
    title: 'Käsikirjoitus',
    members: [
      <strong>Peitsa Leinonen</strong>,
      <strong>Riina Lahtinen</strong>,
      <strong>Sara Nortunen</strong>,
      'Alfred Altsten',
      'Elina Pikkarainen',
      'Harri Kailanto',
      'Iris Enwald',
      'Janina Luhtala',
      'Janne Järvinen',
      'Jari Leppänen',
      'Maisa Virtanen',
      'Meeri Härmä',
      'Miikka Saraniemi',
      'Milla Mäenhovi',
      'Roope Salonen',
      'Sanna Kallio',
      'Severi Mäkelä',
      'Sonja Nikander',
      'Tuure Peltonen',
    ],
  },
  {
    title: 'Lavastus',
    members: [
      <strong>Miika Kotajärvi (Lavastus)</strong>,
      <strong>Sakari Huuskola (Näyttämömestari)</strong>,
      'Anni Kallunki',
      'Annika Aro',
      'Antti Niiranen',
      'Anu Juvonen',
      'Ari Alanko',
      'Daniel López',
      'Eemeli Virkajärvi',
      'Eetu Airaksinen',
      'Eetu Kapanen',
      'Emma Taalikka',
      'Emma Toikkanen',
      'Emmi Oikkonen',
      'Henri Kiiski',
      'Jani Hilliaho',
      'Jenna Ylhäinen',
      'Joona Lumitähti',
      'Juho Jokelainen',
      'Justus Leppäaho',
      'Kiti Suupohja',
      'Laura Kastell',
      'Lauri Pitkäjärvi',
      'Maisa Virtanen',
      'Maria Arro',
      'Marlene Majuri',
      'Matilda Kivilahti',
      'Matti Kujala',
      'Miki Tolonen',
      'Rolando San Martín Medina',
      'Sami Hartala',
      'Shayeste Shamsi',
      'Silja Mäkinen',
      'Tekla Kaasalainen',
      'Tiitus Lehtonen',
      'Ulrika Kaara',
      'Valtteri Martikainen',
      'Vilina Wikström',
      'Ville Nousiainen',
    ],
  },
  {
    title: 'Lipunmyynti',
    members: [
      <strong>Maria Arro</strong>,
      'Erik Erola',
      'Ari Alanko',
      'Minnow Lappi'
    ],
  },
  {
    title: 'Logistiikka',
    members: [
      <strong>Justus Leppäaho</strong>,
      'Antti Niiranen',
      'Ari Alanko',
      'Eemeli Virkajärvi',
      'Emmi Oikkonen',
      'Lauri Pitkäjärvi',
      'Tiitus Lehtonen',
    ],
  },
  {
    title: 'Mainosmyynti',
    members: [
      <strong>Antti Kemppi</strong>,
      'Jani Patrakka',
      'Olivia Keskinen',
      'Tiitus Lehtonen',
      'Iiris Envald',
    ],
  },
  {
    title: 'Markkinointi',
    members: [
      <strong>Janita Rintala</strong>,
      'Henna-Maria Vähämäki',
      'Nettaella Raikunen',
      'Olivia Keskinen',
      'Inari Isohanni',
    ],
  },
  {
    title: 'Puvustus',
    members: [
      <strong>Erika Kaski</strong>,
      <strong>Riina Lahtinen</strong>,
      'Aino Pöykkö',
      'Annika Aro',
      'Emma Brown',
      'Emma Taalikka',
      'Emma Toikkanen',
      'Emmi Oikkonen',
      'Enni Hartikainen',
      'Hanna Suhonen',
      'Heidi Hansen-Haug',
      'Heidi Tuomela',
      'Heidi Verkkosaari',
      'Henna Salosyrjä',
      'Inari Isohanni',
      'Juho Jokelainen',
      'Krista Uusi-Kinnala',
      'Maisa Virtanen',
      'Marlene Majuri',
      'Matilda Kivilahti',
      'Matti Kujala',
      'Niina Palmu',
      'Nina Parviola',
      'Sanna Kallio',
      'Sanni Laine',
      'Taneli Hongisto',
      'Teemu Peltoniemi',
      'Tekla Kaasalainen',
      'Ulrika Kaara',
    ],
  },
  {
    title: 'Ruokatiimi',
    members: [
      <strong>Sanni Laine</strong>,
      'Alfred Altsten',
      'Alli Ollikainen',
      'Ari Alanko',
      'Erika Kaski',
      'Iiris Enwald',
      'Maisa Virtanen',
      'Marlene Majuri',
      'Rolando San Martín Medina',
      'Rupesh Majhi',
      'Sonja Haapala',
    ],
  },
  {
    title: 'Sanoitus',
    members: [
      <strong>Miikka Saraniemi</strong>,
      'Anni Kallunki',
      'Enni Hartikainen',
      'Iiris Enwald',
      'Jani Patrakka',
      'Jenny Ollila',
      'Maisa Virtanen',
      'Pyry Qvick',
    ],
  },
  {
    title: 'Taikatiimi (maski & tukka)',
    members: [
      <strong>Hanna Suhonen (Tukka)</strong>,
      <strong>Minna Rajala (Maski)</strong>,
      'Annika Aro',
      'Emma Brown',
      'Emmi Oikkonen',
      'Enni Hartikainen',
      'Hanna Suhonen',
      'Heidi Tuomela',
      'Heidi Verkkosaari',
      'Helena Ukkola',
      'Henna Salosyrjä',
      'Henrietta Reunanen',
      'Iida Päärnilä',
      'Jenni Nerg',
      'Milla Mäenhovi',
      'Saga Porjo',
      'Saana Haapala',
      'Sanna Kallio',
      'Sara Nortunen',
      'Shayeste Shamsi',
      'Veera Lignell',
    ],
  },
  {
    title: 'Taltiointi',
    members: [
      <strong>Juha-Matti Hakojärvi</strong>,
      'Aleksi Eilo',
      'Anni Kallunki',
      'Arttu Timonen',
      'Daniel López',
      'Hannu Vuolasaho',
      'Helena Hämölä',
      'Joona Lumitähti',
      'Joonas Laine',
      'Juho Pyykkönen',
      'Justus Leppäaho',
      'Mika Pajala',
      'Mikko Kohonen',
      'Olivia Keskinen',
      'Petteri Niemi',
      'Sanni Laine',
      'Siina Siuvo',
      'Sofi Lemieux',
    ],
  },
  {
    title: 'Tanssi ja koreografia',
    members: [
      <strong>Emma Jokela</strong>,
      <strong>Siina Siuvo</strong>,
      'Adelia Aalto',
      'Anni Lehtimäki',
      'Berfin Bakir',
      'Eevi Pitkänen',
      'Ella Paananen',
      'Lisa Salo',
      'Meiju Koivuniemi',
      'Olivia Myntti',
      'Ronja Lipsonen',
    ],
  },
  {
    title: 'Tarpeisto',
    members: [
      <strong>Lauri Pitkäjärvi</strong>,
      'Aino Pöykkö',
      'Anni Kallunki',
      'Annika Aro',
      'Antti Niiranen',
      'Anu Juvonen',
      'Ari Alanko',
      'Eemeli Virkajärvi',
      'Eetu Airaksinen',
      'Esa Lakaniemi',
      'Helena Hämölä',
      'Henna Salosyrjä',
      'Jani Hilliaho',
      'Joona Lumitähti',
      'Juho Jokelainen',
      'Jutta Tilvis',
      'Lasse Enäsuo',
      'Laura Kastell',
      'Lauri Kuusisto',
      'Maisa Virtanen',
      'Maria Arro',
      'Matti Kujala',
      'Meeri Härmä',
      'Miki Tolonen',
      'Niina Palmu',
      'Sara Nortunen',
      'Sanna Kallio',
      'Sanni Laine',
      'Shayeste Shamsi',
      'Susanna Rouru',
      'Ulrika Kaara',
      'Vilina Wikström ',
    ],
  },
  {
    title: 'Tekniikka',
    members: [
      <strong>Erik Erola</strong>,
      'Antti Niiranen',
      'Eemeli Virkajäri',
      'Eetu Airaksinen',
      'Hannu Vuolasaho',
      'Inari Isohanni',
      'Jan Heikkilä',
      'Joona Lumitähti',
      'Joona Malmi',
      'Joonas Laine',
      'Justus Leppäaho',
      'Jutta Tilvis',
      'Matti Perkiömäki',
      'Mikko Kohonen',
      'Minnow Lappi',
      'Roope Paukku',
      'Sami Hartala',
      'Sampo Syrjänen',
      'Tiitus Lehtonen',
    ],
  },
  {
    title: 'Visualismi',
    members: [
      <strong>Sisu Suominen</strong>,
      <strong>Vivian Lunnikivi</strong>,
      'Aleksi Eilo',
      'Anu Juvonen',
      'Eemeli Virkajärvi',
      'Mika Kuitunen',
      'Peitsa Leinonen',
      'Shayeste Shamsi',
      'Siina Siuvo',
    ],
  },
  {
    title: 'Velhot',
    members: [
      <strong>Matti Kujala</strong>,
      'Eemeli Virkajärvi',
      'Henri Kiiski',
      'Juho Jokelainen',
      'Kiti Suupohja',
      'Sami Hartala',
      'Ulrika Kaara',
      'Ville Nousiainen',
    ],
  },
  {
    title: 'WWW',
    members: [
      <strong>Vivian Lunnikivi</strong>,
      'Taneli Hongisto',
    ]
  }
];

const shows = [
  {
    city: 'Helsinki',
    building: 'Aleksanterin teatteri',
    date: 'Ma 14.11.',
    time: '18',
    link: 'https://kauppa.naaspeksi.fi/naaspeksi/jano/',
  },
  {
    city: 'Tampere I',
    building: 'Tampere-talo',
    date: 'To 17.11.',
    time: '18',
    link: 'https://kauppa.naaspeksi.fi/naaspeksi/jano/',
  },
  {
    city: 'Tampere II',
    building: 'Pakkahuone I, K-18',
    date: 'Ma 21.11.',
    time: '18',
    link: 'https://www.tiketti.fi/naaspeksi-2022-jano-tullikamarin-pakkahuone-tampere-lippuja/85881',
    linkTitle: 'Tiketti.fi',
  },
  {
    city: 'Tampere III',
    building: 'Pakkahuone II, K-18',
    date: 'Ti 22.11.',
    time: '18',
    link: 'https://www.lippu.fi/artist/naaspeksi/',
    linkTitle: 'Lippu.fi',
  },
  {
    city: 'Tampere III',
    building: 'Pakkahuone II, K-18',
    date: 'Ti 22.11.',
    time: '18',
    link: 'https://www.tiketti.fi/naaspeksi-2022-jano-tullikamarin-pakkahuone-tampere-lippuja/85881',
    linkTitle: 'Tiketti.fi',
  },
  {
    city: 'Tampere IV',
    building: 'Pakkahuone III, K-18',
    date: 'Ke 23.11.',
    time: '18',
    link: 'https://www.tiketti.fi/naaspeksi-2022-jano-tullikamarin-pakkahuone-tampere-lippuja/85881',
    linkTitle: 'Tiketti.fi',
  },
];

// --- all required data definitions above  ---

const Raina2022 = ({ location }) => (
  <DefaultLayout>
    <Helmet htmlAttributes={{ class: styles.root2022 }} />

    <Metadata
      page={{
        title: 'Jano',
        excerpt: 'Rainasivut vuoden 2022 Nääspeksi-produktiolle',
      }}
      location={location}
    />

    <InfoBoard />
  </DefaultLayout>
);

const InfoBoard = () => {
  return (
    <div>
      <div>
        <SynopsisColumn />
        <ShowsColumn shows={shows} />
        <CrewColumn crew={crew} />
        <SponsorColumn sponsors={sponsors} />
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <header className={styles.logo}>
      <img
        src={titleImg}
        alt="Jano"
        title="Jano"
        className={styles.titleImg}
        height="17rem"
      />
    </header>
  );
};

const synopsisInnerStyle = { 
  border: '2pt solid #da8b28',
  textAlign: 'center',
}
const SynopsisColumn = () => {
  return (
    <div className={styles.synopsisStyle2022}>
      <div className={styles.contentPadding} style={synopsisInnerStyle}>

        <Header style={{ margin: '-3rem 0em 3em 0em' }} />

        <p>
          Maailma on rikki. Muinaisina aikoina maa oli veden peittämä. 
          Mutta ihmisten jano kasvoi ja kasvoi. Sitä janoa eivät riittäneet 
          sammuttamaan joet, järvet eivätkä valtameret, siispä ne katosivat. 
          Silloin maailma menetti järkensä. Enää ei ole vettä, ei ruokaa, 
          ei suojaa. Nyt hallitsevat vahvimmat, ja heikot matelevat heidän 
          jaloissaan. Mutta Hän ei kumarra ketään.
        </p>

        <p>
          Legendan mukaan muinainen maailma jätti jälkeensä toivon pisaran, 
          joka löytyessään voisi virvoittaa kansan ja kanjonit. Uutta alkua 
          etsiessä on uhrattu paljon, mutta tyydyttääkö mikään aavikon janoa?
        </p>

        <p>
          NääsPeksin 24. produktio vie katsojansa jännittävälle löytöretkelle 
          maailmanlopun jälkeisiin tunnelmiin etsimään elämän eliksiiriä: vettä.
        </p>
      
      </div>
    </div>
  );
};

const showColumnStyle = {
  display: 'flex',
  flexDirection: 'row', 
  flexWrap: 'wrap',
  justifyContent: 'space-between',
};
const showsInnerStyle = { 
  border: '2pt solid #da8b28',
  textAlign: 'center',
  padding: '1rem',
}
const ShowsColumn = ({ shows }) => {
  return (
    <div className={styles.synopsisStyle2022}>
      <div className={styles.contentPadding} style={showsInnerStyle}>
        <h1>Esitykset</h1>

        <p>
          Kaikki näytökset esitetään suomeksi ja näytökset alkavat 
          tasatunnein, kestäen väliajan kanssa noin kolme tuntia. 
          Ovet aukeavat klo. 17 ja salin istumapaikat täytetään vapaasti 
          saapumisjärjestyksessä.
        </p>

        <p>
          Pakkahuoneen näytöksissä on ikäraja K-18.
        </p>

        <p>
          Lippuihin liittyvissä kysymyksissä ota yhteyttä sähköpostitse: 
          tuottajat@naaspeksi.fi.
        </p>

        <div style={showColumnStyle}>

          {shows.map((show, i) => {
            return <Ticket
              city={show.city}
              building={show.building}
              date={show.date}
              time={show.time}
              link={show.link}
              linkTitle={show.linkTitle}
              key={`show-${i}`} />;
          })}

          <div className={styles.hoverable}>
            <a href='https://kauppa.naaspeksi.fi/naaspeksi/jano/'>
              <h2>Liput</h2>
              <p style={{ margin: '0' }}>25 € Työssäkäyvät</p>
              <p style={{ margin: '0' }}>30 € Kannatuslippu</p>
              <p style={{ margin: '0 0 1em 0' }}>15 € Opiskelijat, eläkeläiset, varushenkilöt, jne.</p>
            </a>
          </div>

        </div>

      </div>
    </div>
  );
};

const Ticket = ({ city, building, date, time, link, linkTitle }) => {
  return <div className={styles.hoverable}>
    <a href={link}>
      <h2>{city}</h2>
      <p style={{ margin: '0' }}><strong>{date} klo. {time}</strong></p>
      <p style={{ margin: '0' }}><strong>{building}</strong></p>
      <p style={{ margin: '0 0 1em 0' }}>{(linkTitle ? linkTitle : 'Lippukauppaan') + ' »'}</p>
    </a>
  </div>
}

const crewInnerStyle = {
  border: '2pt solid #da8b28',
  textAlign: 'left',
}
const CrewColumn = ({ crew }) => {
  return (
    <div className={styles.synopsisStyle2022}>
      <div className={styles.contentPadding} style={crewInnerStyle}>
        <Crew crew={crew} />
      </div>
    </div>
  )
}
const crewStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
}
const Crew = ({ crew }) => (
  <div className={styles.contentPadding}>

    <h1 style={{ textAlign: 'center' }}>Tekijät</h1>

    <div className={styles.toggleBoxGrid}>
      <div style={crewStyle}>
        {crew
          .map((section, i) => (
            <CrewSection 
              title={section.title} 
              members={section.members} 
              key={`crew-sec-${i}`} />
        ))}
      </div>
    </div>

  </div>
);
const CrewSection = ({ title, members }) => (
  <ToggleBox
    title={title}
    defaultOpen={false}
    titleClassName={styles.toggleTitle}
    className={styles.toggleBox}
    style={{ minWidth: 'max(100pt, 45%)', height: 'fit-content' }}
  >
    <ul>
      {members.map((member, i) => (
        <li id={`${title}-${member}`} key={`member-${i}`}>{member}</li>
      ))}
    </ul>
  </ToggleBox>
);

const SponsorColumn = ({ sponsors }) => (
  <div className={styles.synopsisStyle2022}>
    <div className={styles.contentPadding} style={synopsisInnerStyle}>
      <h1>Yhteistyössä</h1>

      <div className={styles.adsList}>
        {sponsors.map((sponsor, i) => (
          <Sponsor href={sponsor.href} src={sponsor.src} title={sponsor.title} key={`sponsor-${i}`} />
        ))}
      </div>
    </div>
  </div>
);
const Sponsor = ({ href, src, title }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    <img src={src} alt={title} title={title} />
  </a>
);

export default Raina2022;
